@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




$accordion-header: #fafaf7;

[data-sparta-container] .#{$moduleNameSpace} {
  margin-top: $spacing-20;
  margin-bottom: $spacing-20;

  @media #{$medium-up} {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 24px;
    text-align: center;

    @media #{$medium-up} {
      font-size: 28px;
      margin-bottom: 30px;
    }
    @media #{$large-up} {
      font-size: 34px;
    }
  }

  .content-row {
    @media #{$large-up} {
      width: 80%;
    }

    h3 {
      font-family: $cnx-medium-family, $cnx-font-fallbacks;
      font-size: 20px;
      color: $color-gray;
      margin-bottom: 30px;
    }

  }

  p {
      margin-bottom: 15px;
  }
}
